.media-body {
  .MuiAvatar-root {
    margin: auto;
  }

  h6 {
    text-align: center;
  }
}

.paper-with-margin{
  margin-bottom: 20px;
}