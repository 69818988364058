/*Dashboard Styles*/
.box {
  position: relative;

  .box-top,
  .box-bottom {
    height: 100px;
    padding: 32px 15px;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    font-weight: $display1-weight;
    .size-h5 {
      font-size: 24px;
      margin-left: 2px;
    }
    .material-icons {
      font-size: 40px;
      line-height: 40px;
    }
  }

  .box-bottom {
    border-top: 1px solid rgba($black, .15);
  }

  .box-info {
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -12px;
    text-align: center;

    span {
      height: 24px;
      display: inline-block;
      padding: 4px 10px;
      text-transform: uppercase;
      line-height: 14px;
      background-color: $white;
      border: 1px solid rgba($black, .15);
      font-size: 12px;
      color: $text-muted;
      border-radius: 1em;
      .material-icons {
        line-height: 14px;
        font-size: 11px;
      }
    }
  }
}

.contact-list {
  margin-bottom: 14px;

  & i {
    margin-right: 14px;
    color: theme-color("primary");
  }

  & .media {
    margin-bottom: 18px;

    @media screen and (max-width: 1199px) {
      margin-bottom: 10px;
    }
  }
}

.temp-section {
  padding-top: 40px;
  @include display-flex(flex, row, wrap);
  @include justify-content(center);

  .temp-point,
  .detail-icon {
    font-size: 45px;

    @media screen and (max-width: 991px) {
      font-size: 35px;
    }

    @media screen and (max-width: 575px) {
      font-size: 30px;
    }
  }

  @media screen and (max-width: 991px) {
    padding-top: 25px;
  }

  @media screen and (max-width: 575px) {
    padding-top: 15px;
  }
}

.assignment-table {
  & tbody tr:nth-child(odd) {
    background-color: $gray-300 !important;
  }
  & td {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.status-cell {
  min-width: 100px;
  max-width: 120px;
  padding-right: 24px !important;
  & .badge {
    min-width: 70px;
  }
}

.thumbnail-list {
  margin-bottom: 0;

  & .thumbnail-item {
    padding: 0 2.5px;
    display: inline-block;
    width: 33.333%;
    margin-bottom: 5px;

    @media screen and (max-width: 767px) {
      width: 50%;
    }

    @media screen and (max-width: 575px) {
      width: 33.333%;
    }

    & img {
      max-width: 100%;
    }
  }
}

.post-list {
  & .post-image .grid-thumb-equal {
    padding-bottom: 100%;
  }
}

/*Ecommerce Dashboard Styles*/
.chart-f30 {
  font-size: 30px;

  @media screen and (max-width: 1366px) {
    font-size: 24px;
  }

  @media screen and (max-width: 1099px) {
    font-size: 20px;
  }
}

.chart-f20 {
  font-size: 20px;

  @media screen and (max-width: 1099px) {
    font-size: 18px;
  }

  @media screen and (max-width: 1099px) {
    font-size: 16px;
  }
}

.chart-order {
  & .col-5 {
    order: 2;
    text-align: center;

    @media screen and (max-width: 575px) {
      order: 1;
    }
  }

  & .col-7 {
    order: 1;

    @media screen and (max-width: 575px) {
      order: 2;
    }
  }
}

.tab-notifications {
  margin-left: -$jr-card-margin;
  margin-right: -$jr-card-margin;
  margin-top: -10px;
  border-bottom: 1px solid $gray-500;

  @media screen and (max-width: 567px) {
    button,
    button * {
      font-size: 12px !important;
    }
  }
}

.chart-title {
  & h2 {
    font-size: 22px;
    margin-bottom: 5px;
  }
}

.img-fluid {
  width: 100%;
  max-height: 200px;
}

.comment-footer {
  & button {
    margin-bottom: 6px;
  }
}

/*Intranet Dashboard Styles*/
.net-chart {
  padding-left: 80px;
  padding-right: 15px;
  min-height: 100px;
  position: relative;
  overflow: hidden;
  @include display-flex();
  @include align-items(center);

  & .jr-card-thumb {
    margin: 0;
    width: $size-50;
    height: $size-50;
    color: $white;
    font-size: 20px;
    @include border-radius($border-radius-circle);
    @include display-flex(flex, column, nowrap);
    @include justify-content(center);
    @include align-items(center);
    @include box-shadow(0 6px 20px 0 rgba($gray-900, 0.19), 0 8px 17px 0 rgba($gray-900, 0.20));
    @include transition(all 100ms ease);
    position: absolute;
    left: 15px;
  }

  &:hover .jr-card-thumb {
    width: 70px;
    height: 100%;
    font-size: 30px;
    @include border-radius(0);
    position: absolute;
    left: 0;
  }
}

.jr-chart-or {
  & .chart-header {
    padding-bottom: 0;
  }
}

.net-chart-hr {
  @include display-flex();
  @include align-items(center);
  min-height: ($size-120 - 2);
  overflow: hidden;
  padding: 0;

  & .jr-card-thumb {
    margin: 0;
    min-width: $size-100;
    align-self: stretch;
    @include border-radius(0 $border-radius-circle $border-radius-circle 0);

    &-img {
      @include display-flex(flex, row, row);
      @include align-items(center);
      @include justify-content(center);
      height: 100%;

      & img {
        @include border-radius(50%);
        width: ($size-60 + 5);
      }
    }
  }

  & .grid-thumb-equal {
    padding-bottom: 130%;
  }

  & .jr-card-body {
    width: calc(100% - #{$size-100});
    padding: 12px;
  }
}

.user-detail-card {
  position: relative;
  @include display-flex();
  overflow: hidden;
  padding: 0;
  min-height: 110px;

  & .user-img {
    display: inline-block;
    width: 62px;
    height: 62px;
    @include box-shadow($avatar-shadow);
    @include border-radius($border-radius-circle);
  }

  & .user-img-container {
    content: '';
    @include display-flex(flex, row, nowrap);
    @include justify-content(flex-end);
    @include align-items(center);
    width: 150px;
    height: 150px;
    @include border-radius($border-radius-circle);
    padding: 16px;
    background-color: map_get($green, base);
    position: absolute;
    top: 50%;
    left: -52px;
    @include translateY(-50%);
  }

  & .jr-card-body {
    padding: 20px 10px 20px 110px;
  }
}

.user-profile {
  padding: 12px 0;
}

.mh-120 {
  min-height: $size-120;
}

.jr-card-header-top {
  @include display-flex();
  @include align-items(center);
  margin-left: -12px;
  margin-right: -12px;
}

.jr-card-header-color {
  padding: 16px $jr-card-padding $jr-card-padding;
  margin: (-$jr-card-padding) (-$jr-card-padding) 30px;
  @include border-radius($border-radius $border-radius 0 0);
  position: relative;

  & .jr-badge-up {
    @include display-flex(flex, row, nowrap);
    @include align-items(center);
    @include justify-content(center);
    width: 45px !important;
    height: 45px !important;
    padding: 5px;
    font-size: ($font-size-lg + 0.25rem);
    color: $white;
    @include border-radius($border-radius-circle);
    position: absolute;
    right: $size-20;
    @include box-shadow($avatar-shadow);
    bottom: -22px;
    z-index: 1;

    & .zmdi-mail-send {
      padding-left: 4px;
    }

    @media screen and (max-width: 1366px) {
      width: 40px !important;
      height: 40px !important;
      font-size: ($font-size-lg + 0.125rem);
      bottom: -20px;
    }
  }
}

.markt-table {
  @media screen and (max-width: 1366px) {
    .user-detail .user-description {
      font-size: $font-size-sm;
    }
  }
}

.todo-cell-group {
  margin-top: -6px;

  & .todo-cell {
    margin-left: -5px;

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}

.social-list-line {
  position: relative;

  &:not(:last-child) {
    & .media-body {
      margin-bottom: 24px;
    }

    &:before {
      border-left: solid 1px $gray-500;
      content: "";
      left: 20px;
      position: absolute;
      top: 5px;
      bottom: 0;
      width: 1px;
      z-index: 1;
    }
  }
}

.list-line-item {
  position: relative;
  padding-left: 30px;

  &:not(:last-child):before {
    border-left: solid 1px $gray-500;
    content: "";
    left: 15px;
    position: absolute;
    top: -30px;
    bottom: -25px;
    width: 1px;
    z-index: 1;
  }
}

.list-line-badge {
  position: absolute;
  left: 11px;
  top: 15px;
  z-index: 2;
  @include border-radius(50%);
  height: 10px;
  width: 10px;
  background-color: $gray-500;
}

.jr-revenue-chart {
  & .recharts-default-legend {
    text-align: left !important;
    margin-bottom: 20px !important;
  }
}

.user-profile-border {
  padding-left: $jr-card-margin;
  padding-right: $jr-card-margin;

  &:not(:last-child) {
    border-bottom: 1px solid $gray-200;
  }
}

.table-userdetail-mmin,
.daily-feed-body {
  margin-top: -15px;
}

.daily-feed-body {
  & .user-profile {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  & + .daily-feed-footer {
    border-top: 1px solid $gray-200;
    padding: 12px $jr-card-margin;
  }
}

.jr-comments {
  & .media-list {
    padding-left: $jr-card-margin;
    padding-right: $jr-card-margin;
    padding-top: 20px;
    padding-bottom: 15px;
    margin-bottom: 0;

    &:hover {
      background-color: lighten($app-primary, 45%);
    }
  }
}

.team-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  @include display-flex(flex, row, nowrap);

  & li:not(:first-child) {
    margin-left: -6px;
  }

  & .user-avatar {
    margin-right: 0;
    cursor: pointer;
    position: relative;
    z-index: 1;
    @include transition($transition-base);

    &:not([class*="sieze-"]) {
      width: 25px !important;
      height: 25px !important;
    }

    &:hover,
    &:focus {
      z-index: 2;
      -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
      transform: scale(1.2);
    }
  }
}

.chart-user-statistics {
  margin: -128px -30px 30px;
  padding: 128px 0 0;
  @include border-radius(0);
  & .jr-card-header {
    padding: 30px 30px 0;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 575px) {
    margin-top: -142px;
    padding-top: 142px;
  }
}