.icon-btn-inline {
  padding: 0 !important;
  font-size: 1.0rem !important;
}

.text-lg {
  font-size: large;
}

.ck-balloon-panel{z-index:9999 !important}

.bg-info-custom {
  background-color: #FFFF99 !important;
}

.bg-info-approved-custom {
  background-color: #CCCCFF !important;
}